@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --min-responsive-size: 200px;
  --vw-screen: max(100vw, var(--min-responsive-size));

  --background-color: #FAFAFA;
  --text-color: black;
  --light-blue-color: #28B5E1;
  --ligth-green-color: #96D884;
  --blue-v2-color: #2C72DA;
  --blue-color: #0A1085;
  --light-gray-color: #DDDDDD;
  --gray-color: #616161;
  --navbar-color: rgba(250, 250, 250, .75);
  --orange-color: #FFA15B;
  --primary-color: #2A30C6;
  --pink-color: #D997D1;
  --purple-color: #6D2C78;
  --red-color: #eb1e39;
  --fucsia-color: #95398A;
  --fucsia-10a-color: rgba(149, 57, 138, .1);

  --box-background: linear-gradient(to right bottom, transparent, rgba(35, 38, 100, 0.54));
  --blue-box-background: linear-gradient(106.23deg, rgba(143, 182, 240, 0.33) 6.78%, rgba(44, 114, 218, 0.04) 52.15%, rgba(44, 114, 218, 0.21) 98.07%);
  --ligthblue-box-background: linear-gradient(106.23deg, rgba(136, 201, 222, 0.33) 6.78%, rgba(142, 210, 232, 0.04) 52.15%, rgba(142, 210, 232, 0.21) 98.07%);
  --pink-box-background: linear-gradient(103.86deg, rgba(252, 233, 250, 0.33) 6.45%, rgba(217, 151, 209, 0.2) 46.83%, rgba(220, 154, 212, 0.47) 87.69%);
  --fucsia-box-background: linear-gradient(103.86deg, rgba(249, 140, 236, 0.2) 6.45%, rgba(149, 57, 138, 0.1) 50.04%, rgba(149, 57, 138, 0.4) 87.69%);
  --fucsia-blue-gradient: linear-gradient(102.15deg, #95398A 0.15%, #28B5E1 117.56%);
  --violet-fucsia-gradient: linear-gradient(to left top, #6D2C78, #AA31A8, #DA5FCB);
  --gradient-border-mask: linear-gradient(var(--background-color) 0 0);
  
  --box-border-sm: 1px solid var(--fucsia-color);
  --box-border-md: 2px solid var(--fucsia-color);
  --box-border-lg: 3px solid var(--fucsia-color);
}

body.dialog-open,
body.burger-open,
body.modal-open {
  overflow: hidden;
}

.about-words,
.about-words svg {
  max-width: 1280px;
  width: 100%;
}

.account-links {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}

.account-links > * {
  align-items: center;
  border: var(--box-border-sm);
  border-radius: .5rem;
  display: flex;
  gap: .75rem;
  justify-content: space-between;
  padding: .75rem;
}

.account-plans {
  border-color: var(--fucsia-color);
}

.account-plans-item {
  background: var(--fucsia-10a-color);
  border: var(--box-border-sm);
  border-radius: .5rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  position: relative;
}

.analysis-aside {
  background-color: var(--fucsia-10a-color);
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
  left: 0;
  position: fixed;
  transform: translateY(-50%);
  top: 50%;
}

.analysis-aside ul {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: .25rem;
  padding: .5rem 0;
  position: relative;
}

.analysis-aside ul > li > a {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: .5rem 1rem;
  transition: color linear .25s;
}

.analysis-aside ul > li > a:hover {
  color: white;
}

.analysis-aside ul > li > a svg {
  position: relative;
  z-index: 1;
}

.analysis-aside ul > li > a > span {
  align-items: center;
  background-color: var(--fucsia-color);
  border-radius: .5rem;
  color: white;
  display: inline-flex;
  font-weight: 500;
  height: 2.5rem;
  left: .5rem;
  opacity: 0;
  padding-left: 2.5rem;
  padding-right: 1rem;
  position: absolute;
  text-wrap: nowrap;
  transform: translateX(-110%);
  transition: all linear .25s;
}

.analysis-aside li > a:hover > span {
  opacity: 1;
  transform: translateX(0);
}

.analysis-box {
  border-radius: 1.5rem;
  border: var(--box-border-md);
}

.analysis-box[role="tab"] {
  border-color: var(--light-blue-color);
}

.analysis-box[role="tab"][aria-selected="true"] {
  background-color: #F3F4F5;
}

.analysis-box-item {
  border-color: var(--fucsia-color);
  padding: 0 1.5rem;
}

.analysis-box-title {
  align-items: center;
  color: var(--fucsia-color);
  display: flex;
  flex-flow: row nowrap;
  gap: .5rem;
  margin-bottom: .25rem;
}

.analysis-filters {
  background-color: var(--fucsia-10a-color);
  border-radius: 1.5rem;
  display: flex;
  flex-flow: row wrap;
  font-weight: 500;
  gap: .5rem;
  max-width: 100%;
  padding: .25rem;
  text-align: center;
}

.analysis-filters label {
  border-radius: 1.5rem;
  cursor: pointer;
  margin: 0 auto;
  outline: 0;
  padding: .25rem 1rem;
}

.analysis-filters label input {
  display: none;
}

.analysis-filters label:has(input:checked) {
  background-color: var(--fucsia-color);
  color: white;
}

.analysis-list-item {
  border-radius: 1.5rem;
  border: var(--box-border-md);
}

.analysis-list-item[data-toshow="true"] {
  border: var(--box-border-lg);
}

.analysis-list-item[data-toshow="true"]::after {
  aspect-ratio: 1;
  background-color: var(--fucsia-color);
  border-radius: 50%;
  content: "";
  right: .75rem;
  position: absolute;
  top: .75rem;
  width: 1rem;
}

.analysis-list-item-rating {
  align-items: center;
  aspect-ratio: 1;
  background-color: var(--light-gray-color);
  border-radius: 50%;
  color: white;
  display: flex;
  font-weight: 500;
  font-size: 1.75rem;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  padding: 1rem;
}

.analysis-list-item-rating[data-color="red"] {
  background-color: var(--red-color);
}

.analysis-list-item-rating[data-color="orange"] {
  background-color: var(--orange-color);
}

.analysis-list-item-rating[data-color="green"] {
  background-color: var(--ligth-green-color);
}

.analysis-result-item {
  padding: .75rem 0;
}

.analysis-result-item:not(:last-child) {
  border-bottom: var(--box-border-sm);
}

.analysis-result-item ul {
  padding: 0 .5rem;
  list-style-type: disc;
  list-style-position: inside;
}

.analysis-result-tags {
  display: flex;
  flex-flow: row wrap;
  gap: .5rem;
}

.analysis-result-tags > div {
  background-color: var(--fucsia-10a-color);
  border-radius: .5rem;
  padding: .25rem 1rem;
}

.analysis-result-title {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: .5rem;
}

.analysis-result-title svg {
  color: var(--fucsia-color);
}

.analysis-text-display {
  cursor: text;
  font-family: "Noto Sans", sans-serif;
}

.analysis-searchbar {
  background-color: var(--fucsia-10a-color);
  border-radius: 1.5rem;
  max-width: 100%;
  padding: 0 .25rem;
}

.analysis-searchbar label {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}

.analysis-searchbar input {
  background-color: transparent;
  flex: 1;
  max-width: 12rem;
  outline: none;
  padding: .5rem .75rem;
  width: 100%;
}

.analysis-text-error {
  color: var(--red-color);
  font-weight: 500;
}

.analysis-text-success {
  color: rgb(2, 165, 2);
  font-weight: 500;
}

.analyze-configuration-label {
  display: flex;
  flex-direction: column;
  gap: .25rem;
}

.analyze-configuration-label input {
  background-color: transparent;
  outline: var(--box-border-sm);
  border-radius: .5rem;
  padding: .75rem;
  width: 100%;
}

.analyze-configuration-label input:focus {
  outline: var(--box-border-md);
}

.analyze-configuration-label input::placeholder {
  font-size: .75rem;
  font-weight: 500;
}

.analyze-confirm-field {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.analyze-confirm-field select,
.analyze-confirm-field span {
  background-color: transparent;
  display: inline-block;
  height: 24px;
}

.analyze-url-shortcut-form {
  border-radius: 1rem;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
}

.analyze-url-shortcut-form > input {
  background-color: transparent;
  flex: 1;
  flex-basis: 8rem;
  height: 3.5rem;
  outline: none;
  padding: .75rem 1rem;
  width: 100%;
}

.analyze-url-shortcut-form > input::placeholder {
  color: var(--text-color);
  font-weight: 500;
}

.analyze-url-shortcut-form > button {
  margin-left: auto;
}

.app-container {
  background-color: var(--background-color);
  box-sizing: border-box;
  color: var(--text-color);
  cursor: default;
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
  min-width: var(--min-responsive-size);
}

.app-container * {
  box-sizing: inherit;
}

.app-container *[aria-disabled="true"] {
  opacity: .5;
}

.auth-form {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 1rem;
  width: 574px;
}

.auth-form-error {
  align-items: center;
  color: var(--fucsia-color);
  display: flex;
  flex-flow: row wrap;
  font-weight: 500;
  gap: .5rem;
  justify-content: flex-start;
  margin-bottom: .5rem;
}

.auth-form-field {
  align-items: center;
  outline: 1px solid var(--light-gray-color);
  border-radius: .75rem;
  display: flex;
  flex-direction: row;
  gap: .5rem;
  justify-content: flex-start;
  height: 55px;
  margin-top: 1.5rem;
  padding: .25rem .75rem;
  position: relative;
}

.auth-form-field:has(input:focus) {
  outline: var(--box-border-md);
}

.auth-form-field > input {
  background-color: transparent;
  padding-top: 1rem;
  width: 100%;
}

.auth-form-field > input:focus {
  border: 0;
  outline: 0;
}

.auth-form-field > input::placeholder {
  color: var(--blue-color);
  opacity: 1;
}

.auth-form-placeholder {
  color: var(--gray-color);
  font-size: 0.875rem;
  position: absolute;
  top: 2px;
}

.auth-form-field-ic > * {
  width: 34px;
  display: grid;
  place-items: center;
  color: var(--light-gray-color);
}

.auth-form-field-message {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  font-size: 11px;
  font-weight: 500;
  gap: .25rem;
  left: 0;
  margin-top: .25rem;
  position: absolute;
  top: 100%;
}

.auth-form-group {
  align-items: flex-start;
  color: var(--fucsia-color);
  display: flex;
  flex-flow: row wrap;
  gap: .5rem;
  justify-content: space-between;
  margin-top: 1.75rem;
  position: relative;
}

.auth-form-group > label {
  display: inline-flex;
  gap: .5rem;
}

.auth-form-options {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  gap: .5rem;
  margin-top: 1.5rem;
}

.banner-fucsia {
  background-color: var(--fucsia-10a-color);
  position: relative;
}

.bg-home-ending {
  position: absolute;
  right: -400px;
  bottom: -50px;
  transform: rotate(-6deg);
}

.box {
  background: var(--fucsia-10a-color);
  border: var(--box-border-md);
  border-radius: 1.5rem;
  position: relative;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  position: relative;
}

.btn:disabled {
  opacity: .75;
}

.btn-fucsia {
  background-color: var(--fucsia-color);
  color: white;
}

.btn-light {
  background-color: var(--fucsia-10a-color);
  color: var(--fucsia-color);
}

.btn-lightblue {
  background-color: var(--light-blue-color);
  color: white;
}

.btn-primary {
  background: var(--fucsia-blue-gradient);
  color: white;
}

.btn-purple {
  background-color: var(--purple-color);
  color: white;
}

.btn-secondary {
  background-color: transparent;
  color: var(--text-color);
}

.btn-secondary::before {
  border: 2px solid transparent;
  border-radius: inherit;
  background: var(--fucsia-blue-gradient) border-box;
  content: "";
  inset: 0;
  mask:
    var(--gradient-border-mask) content-box, 
    var(--gradient-border-mask);
  -webkit-mask:
    var(--gradient-border-mask) content-box, 
    var(--gradient-border-mask);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  position: absolute;
}

.btn-secondary:hover {
  background: var(--fucsia-blue-gradient);
  color: white;
}

.btn-transparent {
  border: var(--box-border-sm);
  background-color: transparent;
  color: inherit;
}

.burger-menu {
  align-items: center;
  backdrop-filter: blur(5px);
  background-color: var(--navbar-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  inset: 0;
  left: 100%;
  position: fixed;
  transition: left .3s linear;
  width: 100%;
}

.burger-menu-options {
  display: flex;
  flex-flow: row wrap;
  gap: .75rem;
  top: 0;
  padding: 1.5rem;
  position: absolute;
  width: 100%;
}

.credit-card {
  align-items: center;
  background-color: white;
  border: var(--box-border-sm);
  border-radius: 1rem;
  display: flex;
  flex-flow: row wrap;
  gap: .75rem;
  padding: 1.5rem 1rem;
}

.credit-card button {
  color: var(--light-blue-color);
  font-weight: 600;
  margin-left: auto;
}

.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
}

.chart-canvas {
  max-height: 200% !important; 
}

.checkbox-input {
  aspect-ratio: 1;
  appearance: none;
  background-color: var(--fucsia-10a-color);
  border-radius: .15em;
  color: var(--fucsia-color);
  cursor: pointer;
  font: inherit;
  height: 1.15em;
  margin: 0;
  transform: translateY(.075em);
}

.checkbox-input:checked {
  accent-color: var(--fucsia-color);
  appearance: auto;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.contact-form label {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="password"] {
  outline: var(--box-border-sm);
  background-color: transparent;
  border-radius: .75rem;
  min-height: 36px;
  padding-left: 12px;
  padding-right: 36px;
  resize: none;
  width: 100%;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form input[type="password"]:focus {
  outline: var(--box-border-md);
}

.contact-form-options {
  align-items: center;
  column-gap: 1.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: .25rem 0;
  row-gap: .75rem;
}

.contact-form-pass {
  position: relative;
}

.contact-form-pass button {
  position: absolute;
  bottom: 0;
  right: .5rem;
  top: 0;
}

.container-def {
  width: 100%;
  max-width: 1248px;
  margin: 0 auto;
}

.cover-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.dialog {
  background-color: transparent;
  color: white;
  inset: 0;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  position: fixed;
  z-index: 35;
}

.dialog::after {
  background-color: rgba(0, 0, 0, .75);
  content: "";
  inset: 0;
  position: fixed;
  z-index: -1;
}

.dialog-analyze {
  background-color: var(--background-color);
  border-radius: .75rem;
  color: var(--text-color);
  width: 60rem;
  max-width: 100%;
}

.dialog-body {
  background-color: var(--background-color);
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
  color: var(--text-color);
  padding: 1.25rem 1rem .75rem;
  width: 32rem;
  max-width: 100%;
}

.dialog-content {
  background-color: var(--background-color);
  border-radius: .75rem;
  color: var(--text-color);
  width: 32rem;
  max-width: 100%;
}

.dialog-close-btn {
  position: absolute;
  top: .75rem;
  right: .75rem;
}

.dialog-options {
  align-items: center;
  background-color: var(--background-color);
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
  color: var(--text-color);
  display: flex;
  flex-flow: row wrap;
  gap: 1.5rem;
  justify-content: flex-end;
  padding: .75rem;
}

.dialog-options .btn {
  border-radius: 6px;
  font-weight: 500;
}

.divided-list {
  display: block;
}

.divided-list li:not(:last-child)::after {
  background-color: var(--fucsia-color);
  content: "";
  display: block;
  height: 1px;
  margin: 1rem 0;
  width: 100%;
}

.dots {
  display: flex;
  flex-direction: row;
  gap: .25rem;
  justify-content: center;
}

.dot-purple {
  aspect-ratio: 1;
  border: 1px solid var(--fucsia-color);
  border-radius: 50%;
  cursor: pointer;
  width: .75rem;
}

.dot-purple[aria-checked="true"] {
  background-color: var(--fucsia-color);
}

.dropdown-label {
  display: inline-block;
}

.dropdown-list {
  padding: .25rem .5rem;
  display: flex;
  flex-direction: column;
  min-width: 120px;
  max-width: 100%;
}

.dropdown-list li a,
.dropdown-list li span {
  display: block;
  padding: .25rem;
}

.dropdown-menu {
  backdrop-filter: blur(5px);
  background-color: var(--navbar-color);
  box-shadow: 1px 3px 4px 3px rgba(0, 0, 0, .1);
  border: 1px solid var(--fucsia-color);
  border-radius: .75rem;
}

.feature-banner-image {
  position: absolute;
  width: max-content;
  z-index: 1;
}

.feature-banner-image::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 340px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    var(--background-color) 25%,
    var(--background-color) 75%,
    var(--background-color) 100%
  );
}

.feature-banner-image img {
  width: auto;
  height: 640px;
}

.hero {
  min-height: 90vh;
  position: relative;
}

.home-banner-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  max-width: 36rem;
}

.home-banner-content h1 {
  background: linear-gradient(90deg, #993986 0%, #28B5E1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.inline-divisor {
  border: .5px solid var(--text-color);
  display: inline-block;
  vertical-align: middle;
}

.link-styled {
  color: var(--fucsia-color);
  cursor: pointer;
  display: inline;
}

.link-styled:hover {
  text-decoration: underline;
}

.main-layout {
  min-height: 100vh;
}

.modal {
  background: var(--background-color);
  border-radius: .75rem;
  color: var(--text-color);
  left: 50%;
  width: 960px;
  max-height: calc(100% - 1rem);
  max-width: calc(100% - 1rem);
  overflow: auto;
  padding: 1.5rem 2rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal::-webkit-scrollbar {
  width: .5rem;
}

.modal::-webkit-scrollbar-thumb {
  border-radius: .25rem;
  border: 1px solid var(--background-color);
  background-color: rgba(97, 97, 97, .75);
}

.modal::-webkit-scrollbar-track {
  margin: .5rem 0;
}

.nav-item {
  display: inline-block;
  font-weight: 500;
  opacity: .75;
  position: relative;
  text-align: center;
}

.nav-item[aria-selected="true"],
.nav-item[aria-current="page"] {
  opacity: 1;
}

.nav-item:hover::after,
.nav-item[aria-current="page"]::after,
.nav-item[aria-selected="true"]::after {
  background-color: var(--fucsia-color);
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
}

.order-history-table {
  width: 100%;
  word-break: keep-all;
  word-wrap: normal;
}

.order-history-table tr {
  border-bottom: var(--box-border-sm);
}

.order-history-table tr th {
  font-weight: bold;
  padding: 1rem .5rem;
}

.order-history-table tr td {
  padding: 1rem .5rem;
  text-align: center;
}

.payment-options {
  align-items: flex-start;
  background: var(--fucsia-10a-color);
  border: var(--box-border-sm);
  border-radius: .75rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.payment-options input[type="radio"] {
  position: relative;
}

.payment-options input[type="radio"]::before {
  background-color: var(--background-color);
  border: 1px solid var(--fucsia-color);
  border-radius: 100%;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

.payment-options input[type="radio"]:checked::before {
  background-color: var(--fucsia-color);
}

.payment-options label {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  width: 100%;
}

.payment-options label:not(:first-child)::before {
  border-top: var(--box-border-sm);
  content: "";
  inset: 0;
  position: absolute;
}

.payment-state-circle {
  display: inline-block;
  border-radius: 50%;
  margin-right: .25rem;
  width: .5rem;
  height: .5rem;
}

.payment-state-circle[aria-label="payed"] {
  background-color: var(--light-blue-color);
}

.popover-content {
  background-color: #f5ecf4;
  border: var(--box-border-sm);
  border-radius: 1.25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: black;
  margin: .75rem;
  max-width: 300px;
  padding: 1rem;
}

.pricing-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: .25rem;
  padding: 0 1.5rem;
  position: relative;
}

.pricing-box .b-mark {
  background-color: var(--fucsia-color);
  border-radius: .75rem;
  color: white;
  padding: .5rem;
  position: absolute;
  right: .75rem;
  top: .75rem;
}

.profile-picture {
  align-items: center;
  aspect-ratio: 1;
  background-color: var(--fucsia-color);
  border: var(--box-border-sm);
  border-radius: 50%;
  color: var(--background-color);
  display: flex;
  justify-content: center;
  font-size: 3rem;
  overflow: hidden;
}

.policy-body {
  padding-top: 3rem;
}

.policy-body a {
  text-decoration: underline;
}

.policy-body h2 {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding-bottom: 1rem;
}

.policy-body h3 {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding-bottom: .75rem;
}

.policy-body p {
  padding-bottom: 1.5rem;
}

.policy-body strong {
  font-weight: bold;
}

.policy-body table {
  border-collapse: collapse;
  margin-bottom: 1.5rem;
  width: 100%;
}

.policy-body td,
.policy-body th {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

.policy-body ul {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: .75rem;
  padding-bottom: 1.5rem;
}

.radio-input {
  position: relative;
  top: 1px;
}

.radio-input::before {
  background-color: var(--background-color);
  border: var(--box-border-sm);
  border-radius: 100%;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

.radio-input:checked::before {
  background-color: var(--fucsia-color);
}

.resume-site-text {
  display: inline-block;
  text-transform: capitalize;
  position: relative;
}

.resume-site-text:not(:first-child) {
  margin-inline-start: .75rem;
  padding-inline-start: .75rem;
}

.resume-site-text:not(:first-child)::before {
  background-color: var(--fucsia-color);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 1px;
}

.rounded-tab-list {
  border: 2px solid var(--fucsia-color);
  border-radius: 1.25rem;
  display: flex;
  flex-flow: row wrap;
  gap: 2px;
  font-weight: bold;
  justify-content: center;
  padding: 2px;
  max-width: 100%;
  text-align: center;
}

.rounded-tab-list *[role="tab"] {
  border-radius: 1.25rem;
  cursor: pointer;
  flex-basis: 7rem;
  flex-grow: 1;
  min-width: fit-content;
  outline: 0;
  padding: .25rem;
  width: 12rem;
}

.rounded-tab-list *[role="tab"]:hover {
  background-color: var(--fucsia-10a-color);
}

.rounded-tab-list *[role="tab"][aria-selected="true"] {
  background-color: var(--fucsia-color);
  color: white;
}

.s-wrapper {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill,minmax(20rem,1fr));
  margin: 0 auto;
  padding: 0 1.5rem;
  width: 100%;
}

.s-wrapper > * {
  max-width: calc(var(--vw-screen) - 3rem);
}

.settings-step-options {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  gap: 1rem;
  padding: 1.5rem;
}

.settings-step-options label {
  border: var(--box-border-md);
  border-radius: .75rem;
  cursor: pointer;
  flex-basis: 220px;
  padding: .5rem;
  text-align: center;
}

.settings-step-options label:hover {
  background-color: var(--fucsia-10a-color);
}

.settings-step-options label[data-checked="true"] {
  background-color: var(--fucsia-color);
  color: white;
}

.settings-step-options label input[type="radio"] {
  display: none;
}

.tab-box {
  border-radius: 1rem;
  cursor: pointer;
  padding: .75rem 1.25rem;
}

.tab-box-blue[aria-selected="true"],
.tab-box-blue:hover {
  background: var(--blue-box-background);
  outline: 1px solid var(--blue-v2-color);
}

.tab-box-ligthblue[aria-selected="true"],
.tab-box-ligthblue:hover {
  background: var(--ligthblue-box-background);
  outline: 1px solid var(--light-blue-color);
}

.tab-box-pink[aria-selected="true"],
.tab-box-pink:hover {
  background: var(--pink-box-background);
  outline: 1px solid var(--pink-color);
}

.tab-box-fucsia[aria-selected="true"],
.tab-box-fucsia:hover {
  background: var(--fucsia-box-background);
  outline: 1px solid var(--fucsia-color);
}

.text-fucsia-def {
  color: var(--fucsia-color);
}

.text-gray-def {
  color: var(--gray-color);
}

.text-lightblue-def {
  color: var(--light-blue-color);
}

.text-red-def {
  color: var(--red-color);
}

.team-box {
  text-align: center;
}

.team-box figure {
  aspect-ratio: 1;
  border: var(--box-border-md);
  border-radius: .75rem;
  overflow: hidden;
  width: 100%;
}

.team-box p {
  margin-top: .25rem;
}

.upload-step-dropzone {
  background-color: var(--fucsia-10a-color);
  border: 2px dashed var(--fucsia-color);
}

.upload-step-file {
  align-items: center;
  border: var(--box-border-md);
  border-radius: .75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 1rem;
}

.upload-step-label {
  align-items: center;
  border-radius: .75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  min-height: 13rem;
  padding: 1rem;
}

.upload-step-url-field {
  background-color: var(--fucsia-10a-color);
  outline: var(--box-border-md);
  border-radius: 1.25rem;
  width: 36rem;
  max-width: 100%;
  padding: 1rem 1.25rem;
}

.upload-step-url-field::placeholder {
  color: rgba(0, 0, 0, .5);
  font-weight: 500;
}

.ws-header {
  overflow: visible;
  position: sticky;
  top: 0;
  transition: top .5s;
  width: 100%;
  z-index: 20;
}

.ws-header::before {
  background-color: var(--navbar-color);
  backdrop-filter: blur(5px);
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
}

.ws-footer {
  background-color: var(--background-color);
  border-top: var(--box-border-md);
  position: relative;
  z-index: 15;
}

@media only screen and (max-width: 330px) {
  .app-container {
    word-break: break-word;
    word-wrap: break-word;
  }

  .modal {
    padding: 1.5rem 1rem;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  from {
      opacity: 0.8;
  }
  to {
      opacity: 1;
  }
}